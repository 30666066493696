import { useState } from "react";
import View from "../../items/js/View";
import Image from "../../items/js/Image";
import List from "./List";
import { AlignLeft } from "lucide-react";
import "../css/Header.css"
import { ScrollCenter } from "../../../config/Helpers";

function Header(){
    const [list, setList] = useState(false)
    return(
        <>
        <View className="parentHeader" dr="row" >
            <AlignLeft className="openListButton" size={35} onClick={() => setList(true)}/>
            <Image onClick={() => ScrollCenter('parentFirstImage')} src={require('../../../assets/images/logos/logo.png')}/>
        </View>
        {list && <List onClick={() => setList(false)} />}
        </>
    )
}

export default Header;