import View from "../../items/js/View"
import Text from "../../items/js/Text"
import Image from "../../items/js/Image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import "../css/Footer.css"
import { faPhone, faPhoneVolume } from "@fortawesome/free-solid-svg-icons"
import { ScrollAnimation } from "../../../config/Helpers"

function Footer(){
    ScrollAnimation('parentFooter','parentFooter')
    return(
        <View className="parentFooter">
            <Image className="logoFooter" src={require('../../../assets/images/logos/logo.png')}/>
            <View className="parentInformation">
            <View className="parentPlatforms" dr="column" >
                <Text h1><li>Plateformes pour nous connecter</li></Text>
                <View className="platforms" dr="row">
                <a href="https://www.instagram.com/optiqueassoud"><FontAwesomeIcon icon={faInstagram} /></a>
                <a href="https://www.facebook.com/optiqueassoud"><FontAwesomeIcon icon={faFacebook} /></a>
                <a href="https://wa.me/212623304566"><FontAwesomeIcon icon={faWhatsapp} /></a>
                </View>
                <Text h1><li>Numéros de téléphone</li></Text>
                <View className="phoneNumbers" dr="column">
                <a href="tel:+212623304566">+212 6 23 30 45 66<FontAwesomeIcon icon={faPhone} /></a>
                <a href="tel:+212539384747">+212 5 39 38 47 47<FontAwesomeIcon icon={faPhoneVolume} /></a>
                </View>
            </View>
            <View className="parentQrCode" dr="column" >
            <Text h1><li>QR code spécifique au site</li></Text>
            <Image src={require('../../../assets/images/background/qrCode.png')}/>
            </View>
            </View>
        </View>
    )
}

export default Footer