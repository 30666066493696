import View from '../../items/js/View';
import Text from '../../items/js/Text';
import '../css/Location.css'
import { MapPin } from 'lucide-react';
import { ScrollAnimation } from '../../../config/Helpers';

function Location(){
    ScrollAnimation('parentLocation', 'parentLocation')
    return(
    <View className="parentLocation">
    <View className="parentVideo">
    <video className='locationVideo' src={require(`../../../assets/videos/location.mp4`)} autoPlay muted loop/>
    </View>
    <View className="parentLocationInformation" dr="column">
        <Text h1><li>Ma localisation à Tanger</li></Text>
        <Text h2>Lots Bassatine N10, Val Fleuri Tanger 📍</Text>
        <a className='linkLocation' href="https://maps.app.goo.gl/dQ9J16pYFCJnah3SA">Localisation <MapPin size={30}/></a>
    </View>
    </View>
    )
}

export default Location;