import View from '../../items/js/View';
import Text from '../../items/js/Text';
import "../css/ShopVideo.css"
import { ScrollAnimation } from '../../../config/Helpers';
function ShopVideo(){
    ScrollAnimation('parentShopVideo','VideoShopAnimation')
    return(
        <View className="parentShopVideo">
            <View className="parentVideo">
            <video className='videoShop' src={require('../../../assets/videos/shop.mp4')} autoPlay muted loop/>
            </View>
            <View className="parentLocationInformation" dr="column">
                <Text h1><li>Vidéo de la boutique</li></Text>
                <p>Les meilleures lunettes et lentilles pour corriger votre vision clairement et confortablement.</p>
            </View>
        </View>
    )
}

export default ShopVideo;