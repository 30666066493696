import { useState } from "react";
import View from "../../items/js/View"
import Image from "../../items/js/Image"
import Text from "../../items/js/Text"
import { ArrowRight, ArrowLeft } from 'lucide-react';
import { ScrollAnimation } from "../../../config/Helpers";
import "../css/Pics.css"
function Pics(){
    const [toRight, setToRight] = useState(true)
    const parentImages = document.querySelector('.parentImages')
    const images = document.querySelectorAll('.parentImages img')
    ScrollAnimation('parentImages','changeMoving')
    images.forEach(image => {
        image.addEventListener('click', () => {
            image.scrollIntoView({block:'center',inline:'center'})
            setTimeout(() => {
                window.scrollBy({
                    top: 1
                })
            },800)
        })
    })
    const handleClick = () => {
        if(toRight){
        parentImages.scrollLeft = parentImages.scrollWidth
        setToRight(false)
    }else{
        parentImages.scrollLeft = -parentImages.scrollWidth
        setToRight(true)
    }
    }

    const ArrowIcon = () => {
        if(toRight){
            return <ArrowRight className="arrowRight" size={50} onClick={() => handleClick()}/>
        }else{
            return <ArrowLeft className="arrowRight" size={50} onClick={() => handleClick()}/>
        }
    }
    return(
        <View className="parentPics" dr="column">
            <View className="headPics" dr="row">
            <Text h1> <li>Ma boutique et mes produits</li></Text>
            {ArrowIcon()}
            </View>
            <View className="parentImages" dr="row">
            <Image src={require('../../../assets/images/places/pic1.jpg')}/>
            <Image src={require('../../../assets/images/places/pic3.jpg')}/>
            <Image src={require('../../../assets/images/places/pic2.jpg')}/>
            <Image src={require('../../../assets/images/places/pic4.jpg')}/>
            <Image src={require('../../../assets/images/places/pic5.jpg')}/>
            <Image src={require('../../../assets/images/places/pic6.jpg')}/>
            <Image src={require('../../../assets/images/places/pic7.jpg')}/>
            <Image src={require('../../../assets/images/places/pic8.jpg')}/>
            <Image src={require('../../../assets/images/places/pic9.jpg')}/>
            <Image src={require('../../../assets/images/places/pic10.jpg')}/>
            </View>
        </View>
    )
}

export default Pics;