import Text from "../../items/js/Text"
import View from "../../items/js/View"
import Image from "../../items/js/Image"
import "../css/Comments.css"
import { ScrollAnimation } from "../../../config/Helpers"

function Comments(){
    ScrollAnimation('parentUser', 'UserAnimation', 'loop')
    return(
        <View dr="column" className="parentComments">
            <View dr="row" className="parentUser">
                <Image src={require('../../../assets/images/pfp/user1.png')}/>
                <Text h1><li>ASMAA Z</li>Excellent opticien, à l'écoute et donne du temps aux patients. personnel très professionnel et agréable.</Text>
            </View>
            <View dr="rowR" className="parentUser">
                <Image src={require('../../../assets/images/pfp/user2.png')}/>
                <Text h1><li>Oumaima Hajji</li>Merci.</Text>
            </View>
            <View dr="row" className="parentUser">
                <Image src={require('../../../assets/images/pfp/user3.png')}/>
                <Text h1><li>Othman Saidi</li>Excellent service et lunettes de haute qualité.</Text>
            </View>
        </View>
    )
}

export default Comments;