import Text from "../../items/js/Text"
import View from "../../items/js/View"
import { ChevronUp, Glasses , PartyPopper, MapPin, MessageSquareMore, Film,Phone } from 'lucide-react';
import '../css/List.css'
import { ScrollCenter } from "../../../config/Helpers";


function List(props){
    return(
        <View className="parentList" dr="column" onClick={props.onClick} >
            <Text h1 onClick={() => ScrollCenter('parentFirstImage')}><li>Haut</li> <ChevronUp size={30}/></Text>
            <Text h1 onClick={() => ScrollCenter('parentPics')}><li>Ma boutique et mes produits</li> <Glasses size={30}/></Text>
            <Text h1 onClick={() => ScrollCenter('parentComments')}><li>Commentaires</li> <MessageSquareMore size={30}/></Text>
            <Text h1 onClick={() => ScrollCenter('parentShopVideo')}><li>Apprendre grâce à la vidéo</li> <Film size={30}/></Text>
            <Text h1 onClick={() => ScrollCenter('parentEvents')}><li>Formation professionnelle</li> <PartyPopper size={30}/></Text>
            <Text h1 onClick={() => ScrollCenter('parentLocation')}><li>Localisation</li> <MapPin size={30}/></Text>
            <Text h1 onClick={() => ScrollCenter('parentFooter')}><li>Connectez nous</li> <Phone size={30}/></Text>
        </View>
    )
}

export default List;