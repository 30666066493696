import View from "../../items/js/View"
import Text from "../../items/js/Text"
import '../css/FirstImage.css'
function FirstImage(){
    return(
        <View dr="column" className="parentFirstImage">
        <View className="shadow" dr="column">
            <Text h1>Assoud Optique</Text>
            <Text h2>Votre vision est claire, votre confort est notre priorité</Text>
        </View>
        </View>
    )
}

export default FirstImage;