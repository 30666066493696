import View from "../../items/js/View";
import FirstImage from "../../modeles/js/FirstImage";
import Header from "../../modeles/js/Header";
import { ChevronsDown } from "lucide-react";
import Location from "../../modeles/js/Location";
import "../css/Home.css"
import Pics from "../../modeles/js/Pics";
import Comments from "../../modeles/js/Comments";
import ShopVideo from "../../modeles/js/ShopVideo";
import Footer from "../../modeles/js/Footer";
import Events from "../../modeles/js/Events";
function Home(){
    function ArrowDown(){
        const parentImages = document.querySelector('.parentImages')
        parentImages.scrollIntoView({block:'center'})
        setTimeout(() => {
            window.scrollBy({
                top: 1
            })
            parentImages.querySelectorAll('img').forEach(img => {
                img.style.scrollSnapAlign = "center"
            })
        },800)
    }
    return(
        <View className="parentHome" dr="column">
        <Header/>
        <FirstImage />
        <View className="parentInformationText" dr="column">
        <p className="informationText">Le magasin offre des services optiques spécialisés, notamment des lunettes et des lentilles pour la correction de la vue, en mettant l'accent sur la fourniture de solutions innovantes et le confort oculaire.</p>
        <ChevronsDown className="arrowDown" size={80} onClick={() => ArrowDown()}/>
        </View>
        <Pics />
        <Comments />
        <ShopVideo />
        <Events />
        <Location />
        <Footer></Footer>
        </View>
    )
}

export default Home;