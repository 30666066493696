import View from "../../items/js/View"
import Text from "../../items/js/Text"
import Image from "../../items/js/Image"
import "../css/Events.css"
import { ScrollAnimation } from "../../../config/Helpers"

function Events(){
    ScrollAnimation('parentEvents','parentEvents')
    return(
    <View className="parentEvents">
        <View className="parentLocationInformation" dr="column">
            <Text h1><li>Événements</li></Text>
            <Text h2>Formation professionnelle</Text>
        </View>
        <View dr="column">
            <Image src={require('../../../assets/images/places/events.jpg')}/>
        </View>
    </View>
    )
}

export default Events;