import { useEffect, useState } from "react";
export const ScrollAnimation = (sectionName, animationName, status) => {
  const [statusAnimation, setStatusAnimation] = useState(false);
  
  useEffect(() => {
      if (status) {
        const elementName = document.querySelectorAll(`.${sectionName}`);
        elementName.forEach((section) => {
        const handleScroll = () => {
          if (window.scrollY >= section.offsetTop - 470 && !statusAnimation) {
            section.style.animationName = animationName;
          }
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
      });
    } else {
        const elementName = document.querySelector(`.${sectionName}`);
      const handleScroll = () => {
        if (window.scrollY >= elementName.offsetTop - 470 && !statusAnimation) {
          setStatusAnimation(true);
          elementName.style.animationName = animationName;
        }
      };
      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [statusAnimation]);
};

export const ScrollCenter = (sectionName) => {
  const elementName = document.querySelector(`.${sectionName}`)
  elementName.scrollIntoView({block:'center'})
  setTimeout(() => {
    elementName.scrollIntoView({block:'center'})
  }, 1000)
}